import {
  onEvent,
  TEASER_VIDEO_CLICK,
  triggerEvent,
  VIDEO_MODAL_CLOSED,
} from '@fec/assets/js/utils/event';
import {
  markTeaserAsNotPlaying,
  markTeaserAsPlaying,
} from '@fec/frontend/modules/content/teaser-ng/teaser-ng';

export class SrfVideoTeaser {
  constructor(element) {
    this.$element = $(element);

    if (this.$element.hasClass('teaser-ng')) {
      this.$wrapperLink = $('.js-video-teaser-link', this.$element.parent());
    } else {
      this.$wrapperLink = $('.js-video-teaser-link', this.$element);
    }

    if (this.$wrapperLink.length) {
      this.id = this.$wrapperLink.attr('id');
      this.assetId = this.$wrapperLink.data('assetid');
      this.$wrapperLink.attr('href', this.$wrapperLink.data('href'));
    } else {
      this.id = this.$element.attr('id');
      this.assetId = this.$element.data('assetid');
    }
    this.registerListener();
  }

  registerListener() {
    onEvent({
      element: this.$element[0],
      eventName: 'click',
      eventHandler: (e) => this.handleClick(e),
    });

    onEvent({
      eventName: TEASER_VIDEO_CLICK,
      eventHandler: ({ detail }) => this.handleVideoTeaserClick(detail),
    });

    onEvent({
      eventName: VIDEO_MODAL_CLOSED,
      eventHandler: () => this.handleModalClose(),
    });
  }

  handleClick(e) {
    e.preventDefault();
    e.stopPropagation();

    triggerEvent(TEASER_VIDEO_CLICK, {
      id: this.id,
      assetId: this.assetId,
    });
  }

  handleVideoTeaserClick(data) {
    if (this.isClickedItem(data)) {
      this.setStatusPlaying();
    } else {
      this.setStatusDefault();
    }
  }

  handleModalClose() {
    this.setStatusDefault();
  }

  setStatusDefault() {
    markTeaserAsNotPlaying(this.$element[0]);
  }

  setStatusPlaying() {
    markTeaserAsPlaying(this.$element[0]);
  }

  isClickedItem(data) {
    return data.id === this.id && data.assetId === this.assetId;
  }

  getElementWithLink() {
    return this.$wrapperLink.length ? this.$wrapperLink : this.$element;
  }
}
