import ClientStorage from '@fec/frontend/foundation/client/storage';
import { SRFBridge } from 'srf-native-bridge';

const STORAGE_KEY = 'srf:rlp:list:view';
const LIST_COLLECTION_SELECTOR = '.js-collection-list';
const BUTTON_SELECTOR = '.js-collection-list-view-switcher';

// These view types should be available as data attributes on the buttons in the collection's title
const VIEW_TYPE_COMPACT = 'compact';
const VIEW_TYPE_DETAIL = 'detail';
const DEFAULT_VIEW_TYPE = VIEW_TYPE_DETAIL;

const VIEW_TYPE_ATTRIBUTE_NAME = 'data-list-view';

export function init() {
  // all collections share a state - that means that if there's multiple list
  // collections on the same page, all can be toggled with every other
  // collection's buttons
  new SrfListCollectionManager();
}

export class SrfListCollectionManager {
  constructor() {
    this.registerListener();
    this.setInitialState();
  }

  registerListener() {
    if (SRFBridge.isRunningInApp()) {
      // changing the list view type via JS bridge between webview and app
      SRFBridge.addListener('SetViewMode', ({ payload }) => {
        const newViewType = payload.isCompact
          ? VIEW_TYPE_COMPACT
          : VIEW_TYPE_DETAIL;
        this.setListViewType(newViewType);
      });
    } else {
      // changing the list view type by button press
      $(document).on('click', BUTTON_SELECTOR, (event) => {
        const newListViewType = $(event.currentTarget).attr(
          VIEW_TYPE_ATTRIBUTE_NAME,
        );
        this.setListViewType(newListViewType);
      });
    }
  }

  setInitialState() {
    let initialViewType = DEFAULT_VIEW_TYPE;

    if (
      ClientStorage.isLocalStorageAvailable() &&
      ClientStorage.hasItem(STORAGE_KEY)
    ) {
      initialViewType = ClientStorage.getItem(STORAGE_KEY);
    }

    this.setListViewType(initialViewType);

    if (SRFBridge.isRunningInApp()) {
      // inform the app which mode was loaded
      SRFBridge.sendEvent({
        eventType: 'InformViewMode',
        payload: {
          isCompact: initialViewType === VIEW_TYPE_COMPACT,
        },
      });
    }
  }

  setListViewType(type) {
    let $collection = $(LIST_COLLECTION_SELECTOR);
    if (type === VIEW_TYPE_COMPACT) {
      $collection
        .addClass('collection-ng--list-compact')
        .removeClass('collection-ng--list');
    } else {
      $collection
        .addClass('collection-ng--list')
        .removeClass('collection-ng--list-compact');
    }

    // set previously active button as inactive and set button with the type as active
    $(BUTTON_SELECTOR).each((_, element) => {
      const $button = $(element);
      const isSelected = $button.attr(VIEW_TYPE_ATTRIBUTE_NAME) === type;

      $button.toggleClass('tab-item--selected', isSelected);
    });

    // save the new view type to localstorage
    if (ClientStorage.isLocalStorageAvailable()) {
      ClientStorage.setItem(STORAGE_KEY, type);
    }
  }
}
