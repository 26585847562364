import { Modal } from '@fec/frontend/modules/containers/modal/modal';
import {
  MEDIA_CAN_PLAY,
  MEDIA_ERROR,
  MEDIA_PLAYING,
  onEvent,
  SET_META_THEME_COLOR,
  TEASER_VIDEO_CLICK,
  TEASER_AUDIO_CLICK,
  triggerEvent,
  VIDEO_MODAL_CLOSED,
} from '@fec/assets/js/utils/event';
import { SrfLetterboxPlayer } from '../../../webview/js/classes/SrfLetterboxPlayer';
import { setFocus } from '@fec/frontend/foundation/a11y';

const CLASS_VIDEO_PLAYING = 'modal-player__video--playing';

export class SrfVideoModal extends Modal {
  /**
   * @param modalId string
   * @param $element jQuery element
   */
  constructor(modalId, $element) {
    super(modalId, $element, null);

    this.isOpen = false;
    this.assetId = null;
    this.letterboxPlayer = null;
    this.$playerContainer = $('.js-modal-player-element', this.$element);
    this.$ratioContainer = $('.js-video-modal-ratio-container', this.$element);
  }

  // Override initial opening of modal upon initialising
  postInit() {}

  /**
   * Additionally to the Close-Button and Escape Key, the video modal listens
   * to events sent by the video teasers and the letterbox player.
   */
  bindEvents() {
    super.bindEvents();

    onEvent({
      eventName: TEASER_VIDEO_CLICK,
      eventHandler: ({ detail }) =>
        this.handleTeaserClick(detail.id, detail.assetId),
    });

    onEvent({
      eventName: TEASER_AUDIO_CLICK,
      eventHandler: () => this.close(),
    });

    // Hide loading icon when player starts playing/is loaded/encountered an error.
    onEvent({
      eventName: [MEDIA_PLAYING, MEDIA_ERROR, MEDIA_CAN_PLAY],
      eventHandler: () => this.handleVideoEvent(),
    });
  }

  handleTeaserClick(elementId, assetId) {
    this.showLoadingIcon();

    // A 2nd click on the currently playing video teaser should close the video modal:
    if (this.isOpen && this.assetId === assetId) {
      this.close();
      return;
    }

    this.$caller = $(`#${elementId}`);
    this.assetId = assetId;
    this.setPlayerRatio(this.$caller);

    if (this.isOpen) {
      this.playVideo();
    } else {
      // Go get the background-color of the modal and send it to show() to
      // trigger the meta-theme-color-change-event with it.
      const videoModalBGColor = window
        .getComputedStyle(document.querySelector('.modal.modal--theme-dark'))
        .getPropertyValue('--t-modal-bg');

      this.show(videoModalBGColor);
    }
  }

  handleVideoEvent() {
    if (this.isOpen) {
      this.hideLoadingIcon();
    } else {
      // Danger Zone - Video is loaded/playing/failing and the modal is closed.
      // Instead of playing in the background, pause the video.
      this.letterboxPlayer?.pause();
    }
  }

  onShowFinished() {
    if (this.$focusTarget.length === 1) {
      setFocus(this.$focusTarget);
    }

    this.isOpen = true;
    this.setA11YProperties(true);

    // when the modal is open and a click happens, close the modal if
    // - it's not a link
    // - not inside of a link
    // - not inside of the modal
    // - not in one of the letterbox-web's menus
    $(window).on('click.playerModal', (event) => {
      let $target = $(event.target);

      // a click deep in the letterbox-web's menu flyouts should not close the modal
      // (menus are fragments in the player and for some mysterious reason, the events bubble up to the player container
      // but can't be caught there)
      if ($target.parents('.srg-menu__panel').length === 1) {
        return;
      }

      if (
        !(
          $target.is('a') ||
          $target.parents('a').length > 0 ||
          $target.parents('.modal').length > 0
        )
      ) {
        if (
          !(
            $target.hasClass('swipeable-area__button--forward') ||
            $target.hasClass('swipeable-area__button--back')
          )
        ) {
          this.close();
        }
      }
    });

    this.playVideo();
  }

  playVideo() {
    if (this.letterboxPlayer === null) {
      this.letterboxPlayer = new SrfLetterboxPlayer({
        containerWrapperId: this.$playerContainer.attr('id'),
        assetId: this.assetId,
        recommendations: true,
        focusOnElement: true,
      });
      this.hideLoadingIcon();
    } else {
      this.letterboxPlayer.load(this.assetId);
    }
  }

  showLoadingIcon() {
    this.$playerContainer.removeClass(CLASS_VIDEO_PLAYING);
  }

  hideLoadingIcon() {
    this.$playerContainer.addClass(CLASS_VIDEO_PLAYING);
  }

  close() {
    if (!this.isOpen) {
      return;
    }

    super.close();
    triggerEvent(SET_META_THEME_COLOR);

    this.isOpen = false;
    $(window).off('click.playerModal');

    if (this.letterboxPlayer) {
      this.letterboxPlayer.pause();
      this.letterboxPlayer.exitPipIfOpen();
    }

    triggerEvent(VIDEO_MODAL_CLOSED);
  }

  setPlayerRatio($teaser) {
    const assetRatio = $teaser.attr('data-asset-ratio');

    if (!assetRatio) {
      return;
    }

    const newRatioClass = `ratio--${assetRatio.replace(':', '-')}`;

    // if the ratio container is not in the correct ratio, remove all classes and set the correct ones
    if (!this.$ratioContainer.hasClass(newRatioClass)) {
      this.$ratioContainer.removeClass().addClass(['ratio', newRatioClass]);
    }
  }
}
