import {
  MEDIA_ENDED,
  MEDIA_PLAYING,
  MEDIA_TIME_UPDATE,
  onEvent,
  VIDEO_AUTOPLAY_START,
  VIDEO_AUTOPLAY_STOP,
} from '@fec/assets/js/utils/event';
import {
  COMPONENTS_PRESETS,
  SrfLetterboxPlayer,
} from '../../../webview/js/classes/SrfLetterboxPlayer';
import { SrfVideoTeaser } from './SrfVideoTeaser';
import { SRFBridge } from 'srf-native-bridge';
import { GET_TEASER_SIZE_FROM_CSS as getTeaserSizeFromCss } from '@fec/frontend/modules/content/teaser-ng/teaser-ng';

const AUTOPLAY_TEASER_SIZES = ['size-m', 'size-l'];

export class SrfAutoplayableVideoTeaser extends SrfVideoTeaser {
  constructor(element) {
    super(element);
    this.$element.addClass('notransition');

    // disable autoplay if the teaser size is not suitable
    if (!this.isAutoplayableTeaserSize(element)) {
      $('.js-inline-player-element', this.$element)
        .removeClass('js-inline-player-element')
        .addClass('js-inline-player-element-disabled');
    }

    this.$playerContainer = $('.js-inline-player-element', this.$element);
    this.$timelineBar = null;
    this.letterboxPlayer = null;
    this.loadingTimeout = null;
  }

  registerListener() {
    super.registerListener();

    onEvent({
      eventName: VIDEO_AUTOPLAY_START,
      eventHandler: ({ detail }) => this.handleAutoplayStartEvent(detail),
    });

    onEvent({
      eventName: [VIDEO_AUTOPLAY_STOP, MEDIA_ENDED],
      eventHandler: ({ detail }) => this.handleAutoplayStopEvent(detail),
    });

    onEvent({
      eventName: MEDIA_PLAYING,
      eventHandler: ({ detail }) => this.handleAutoplayStartedEvent(detail),
    });

    onEvent({
      eventName: MEDIA_TIME_UPDATE,
      eventHandler: ({ detail }) => this.updateTimeline(detail),
    });
  }

  handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    //disable autoplay
    this.$playerContainer.data('autoplay-over', true);

    let payloadData = this.getElementWithLink().data('bridge-payload');

    if (this.letterboxPlayer === null) {
      SRFBridge.playVideo(payloadData.EventPayload);
    } else {
      this.letterboxPlayer.getCurrentTime((currentTime) => {
        this.stopVideoInline();
        payloadData = {
          ...payloadData.EventPayload,
          url:
            this.getElementWithLink().attr('href') +
            '&startTime=' +
            currentTime,
          startTime: currentTime,
        };
        SRFBridge.playVideo(payloadData);
      });
    }
  }

  updateTimeline(data) {
    if (this.isClickedItem(data) && this.$timelineBar) {
      this.$timelineBar.css('width', `${data.percentage}%`);
    }
  }

  handleAutoplayStartedEvent(data) {
    if (this.isClickedItem(data)) {
      if (this.$playerContainer.find('.timeline-bar').length === 0) {
        this.$playerContainer.append(
          '<div class="timeline"><div class="timeline-bar"></div></div><div class="medium__muted-icon"></div>',
        );
        this.$timelineBar = this.$playerContainer.find('.timeline-bar');
      }
      this.$playerContainer.addClass('inlineplayer--playing');
      this.removeLoadingState();
    }
  }

  handleAutoplayStartEvent(data) {
    if (this.isClickedItem(data)) {
      this.playVideoInline();
    }
  }

  handleAutoplayStopEvent(data) {
    if (this.isClickedItem(data)) {
      this.stopVideoInline();
    }
  }

  playVideoInline() {
    if (this.letterboxPlayer === null) {
      this.setLoadingState();
      this.loadingTimeout = window.setTimeout(() => {
        this.letterboxPlayer = new SrfLetterboxPlayer({
          containerWrapperId: this.$playerContainer.attr('id'),
          assetId: this.assetId,
          components: COMPONENTS_PRESETS.ALL_DISABLED,
          muted: true,
          shouldSubscribeToTimeline: true,
        });
        this.letterboxPlayer.disableControls();
      }, 500);
    }
  }

  stopVideoInline() {
    window.clearTimeout(this.loadingTimeout);
    this.$playerContainer.find('.timeline-bar').remove();
    if (this.letterboxPlayer) {
      this.removeLoadingState();
      this.$playerContainer.removeClass('inlineplayer--playing');
      window.setTimeout(() => {
        try {
          this.letterboxPlayer?.destroy();
          this.letterboxPlayer = null;
        } catch (e) {
          this.letterboxPlayer = null;
          this.$playerContainer.remove();
        }
      }, 500);
    }
  }

  setStatusPlaying() {
    return false;
  }

  setLoadingState() {
    this.$element
      .find('.js-autoplay-icon-wrapper')
      .html(
        '<svg viewBox="0 0 22 22" aria-hidden="true" role="presentation">' +
          $('#autoplay-icon-animated').html() +
          '</svg>',
      );
  }

  removeLoadingState() {
    this.$element
      .find('.js-autoplay-icon-wrapper')
      .html(
        '<svg viewBox="0 0 22 22" aria-hidden="true" role="presentation"><use xlink:href="#autoplay-icon" /></svg>',
      );
  }
  isClickedItem(data) {
    return data.id === this.id || data.id === this.$playerContainer.attr('id');
  }

  isAutoplayableTeaserSize(element) {
    // only appropriately sized teaser-ng should display inline autoplay videos
    if (element.classList.contains('js-teaser-ng')) {
      return AUTOPLAY_TEASER_SIZES.includes(getTeaserSizeFromCss(element));
    }
    return true;
  }
}
