import DebounceHelper from '@fec/frontend/foundation/client/debounce-helper';
import ResizeListener from '@fec/frontend/foundation/client/resize-listener';
import {
  MEDIA_ENDED,
  onEvent,
  triggerEvent,
  VIDEO_AUTOPLAY_START,
  VIDEO_AUTOPLAY_STOP,
} from '@fec/assets/js/utils/event';
import { SRFBridge } from 'srf-native-bridge';

const SCROLL_THROTTLE_LIMIT = 100;

export function init() {
  // init the autoplay orchestrator if autoplay is supported (i.e. app context)
  new SrfAutoplay();
}

export class SrfAutoplay {
  constructor() {
    this.playableElements = $('.js-inline-player-element').toArray();
    //the currently active teaser with playing video
    this.$playingElement = null;

    if (this.playableElements.length > 0) {
      this.registerListeners();
      this.onScroll();
    }
  }

  registerListeners() {
    ResizeListener.subscribeDebounced(() => this.onScroll());

    onEvent({
      eventName: 'scroll',
      eventHandler: DebounceHelper.debounce(
        () => this.onScroll(),
        SCROLL_THROTTLE_LIMIT,
      ),
      passive: true,
    });

    //activates autoplay for swipeables
    document
      .querySelectorAll(
        '.js-swipeable-area-wrapper, .js-collection-ng-swipeable',
      )
      .forEach((wrapper) =>
        onEvent({
          eventName: 'scroll',
          eventHandler: DebounceHelper.debounce(
            () => this.onScroll(),
            SCROLL_THROTTLE_LIMIT,
          ),
          element: wrapper,
          passive: true,
        }),
      );

    onEvent({
      eventName: MEDIA_ENDED,
      eventHandler: ({ detail }) => this.handleAutoplayEnded(detail),
    });
  }

  /**
   * checks for playable elements in Viewport
   * stops videos leaving the viewport
   * starts videos entering the viewport as long as no video is currently playing
   */
  onScroll() {
    let elementsInViewport = this.playableElements.filter(
      (elem) => this.isElementInViewport(elem) && this.isElementPlayable(elem),
    );

    if (elementsInViewport.length > 0) {
      let $firstElementInViewport = $(elementsInViewport[0]);

      // Ok, so we have a playable element in the viewport. Nice. One of 3 cases will apply:
      // * nothing is playing. That's the easiest. Just play the first one in the viewport.
      if (!this.$playingElement) {
        this.startAutoplay($firstElementInViewport);
        return;
      }

      // * something is playing. If it's already the element that we were going to play - no problem, because then we don't have to do anything.
      if ($firstElementInViewport.is(this.$playingElement)) {
        return;
      }
      //all good, currently playing video is still in viewport
      if (this.isElementInViewport(this.$playingElement[0])) {
        return;
      }

      this.startAutoplay($firstElementInViewport);
    } else if (this.$playingElement) {
      this.stopAutoplay(this.$playingElement);
    }
  }

  /**
   * Helper function to check if a given object behaves like a Promise
   * @param obj
   * @returns {boolean}
   */
  isPromise(obj) {
    return (
      !!obj &&
      (typeof obj === 'object' || typeof obj === 'function') &&
      typeof obj.then === 'function'
    );
  }

  /**
   * Starts a video if autoplay is active
   * on iOS, we can poll the state by using a provided promise
   *
   * @param $element
   */
  startAutoplay($element) {
    if (this.$playingElement !== null && !this.$playingElement.is($element)) {
      this.stopAutoplay(this.$playingElement);
    }

    if (SRFBridge.isAutoplayEnabled()) {
      this.$playingElement = $element;
      triggerEvent(VIDEO_AUTOPLAY_START, this.getData($element));
    }
  }

  /**
   * Stops a currently playing video
   * @param $element
   */
  stopAutoplay($element) {
    if ($element === null) {
      $element = this.$playingElement;
    }
    triggerEvent(VIDEO_AUTOPLAY_STOP, this.getData($element));
    this.$playingElement = null;
  }

  /**
   * Gets triggered when a video ends naturally
   * @param data
   */
  handleAutoplayEnded(data) {
    if (this.getData(this.$playingElement).assetId === data.assetId) {
      this.$playingElement.data('autoplay-over', true);
      //trigger stop request
      this.stopAutoplay(this.$playingElement);
    }
  }

  /**
   * Helper function to get the assetId of a given teaser element
   * @param $element
   * @returns {{id: *, assetId: *}}
   */
  getData($element) {
    let $teaserLink = $element.closest('.js-video-teaser-link');

    return {
      id: $teaserLink.attr('id'),
      assetId: $teaserLink.data('assetid'),
    };
  }

  /**
   * Helper function to check if autoplay is actively disabled for a given element
   * this could be due to having seen the video already
   * @param el
   * @returns {boolean}
   */
  isElementPlayable(el) {
    return $(el).data('autoplay-over') === undefined;
  }

  /**
   * Helper function to check if a given element is in Viewport
   * @param el
   * @returns {boolean|*}
   */
  isElementInViewport(el) {
    return this.isVisible(el, false);
  }

  isVisible(el, partial) {
    let t = $(el).first();
    if (!t.is(':visible')) {
      return false;
    }

    //we're using getBoundingClientRect to get position of element relative to viewport
    //so we dont need to care about scroll position
    let box = t[0].getBoundingClientRect();

    //let's save window size
    let win = {
      h: $(window).height(),
      w: $(window).width(),
    };

    let heightOffset = box.height / 2;

    //now we check against edges of element

    //firstly we check one axis
    //for example we check if left edge of element is between left and right edge of scree (still might be above/below)
    let topEdgeInRange =
      box.top + heightOffset >= 0 && box.top + heightOffset <= win.h;
    let bottomEdgeInRange =
      box.bottom >= 0 && box.bottom - heightOffset <= win.h;

    let leftEdgeInRange = box.left >= 0 && box.left <= win.w;
    let rightEdgeInRange = box.right >= 0 && box.right <= win.w;

    //here we check if element is bigger then window and 'covers' the screen in given axis
    let coverScreenHorizontally = box.left <= 0 && box.right >= win.w;
    let coverScreenVertically = box.top <= 0 && box.bottom >= win.h;

    //now we check 2nd axis
    let topEdgeInScreen =
      topEdgeInRange &&
      (leftEdgeInRange || rightEdgeInRange || coverScreenHorizontally);
    let bottomEdgeInScreen =
      bottomEdgeInRange &&
      (leftEdgeInRange || rightEdgeInRange || coverScreenHorizontally);

    let leftEdgeInScreen =
      leftEdgeInRange &&
      (topEdgeInRange || bottomEdgeInRange || coverScreenVertically);
    let rightEdgeInScreen =
      rightEdgeInRange &&
      (topEdgeInRange || bottomEdgeInRange || coverScreenVertically);

    //now knowing presence of each edge on screen, we check if element is partially or entirely present on screen
    let isPartiallyOnScreen =
      topEdgeInScreen ||
      bottomEdgeInScreen ||
      leftEdgeInScreen ||
      rightEdgeInScreen;
    let isEntirelyOnScreen =
      topEdgeInScreen &&
      bottomEdgeInScreen &&
      leftEdgeInScreen &&
      rightEdgeInScreen;

    return partial ? isPartiallyOnScreen : isEntirelyOnScreen;
  }
}
