export function init() {
  // listen for clicks on teaser-ng-links and emulate the default browser
  // behaviour via JS
  // keyboard events are no issue, since there are additional links below the
  // teaser-ng DOM that are offscreen-focusable
  document.addEventListener('click', (event) => {
    if (event?.target?.matches('.js-teaser-ng-link')) {
      event.preventDefault();
      event.stopPropagation();
      const { href, target } = event.target.dataset;

      window.open(href, target);
    }
  });
}
