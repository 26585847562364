import { getConfig } from '@fec/assets/js/utils/config';
import { SrfVideoTeaser } from './classes/SrfVideoTeaser';
import { SrfAutoplayableVideoTeaser } from './classes/SrfAutoplayableVideoTeaser';
import { SrfVideoModal } from './classes/SrfVideoModal';
import { init as SrfAutoplayInit } from './classes/SrfAutoplay';
import { init as IFrameAdjustInit } from '../../webview/js/srf-iframe-adjust';
import { init as IframeModalInit } from '../../webview/js/srf-iframe-modal';

export const VIDEO_TEASER_SELECTOR = '.js-video-teaser';
const VIDEO_MODAL_ID = 'modal-player';

export function init() {
  initMedia();
  IFrameAdjustInit();
  IframeModalInit();
}

function initMedia() {
  // The order is important here:
  // If teasers are initialised after the modal, the closing-event is
  // received after the opening event on the teasers!
  initVideoTeasers();
  initVideoModal();
}

function initVideoTeasers() {
  // it's not enough to check the App settings regarding autoplay here, because
  // it can be changed after page load. Thus we can only differentiate between
  // situations where autoplay is possible (app/webview) and where autoplay is
  // not possible (web) and init different teaser scripts for each.
  // In SrfAutoplay we then check the app settings each time a teaser is
  // scrolled into view and either play the video automatically or not.
  const couldAutoplay = getConfig().REPRESENTATION !== 'web';

  $(VIDEO_TEASER_SELECTOR)
    .filter((_, element) => !$(element).data('video-teaser-initialized'))
    .each((_, element) => {
      if (couldAutoplay) {
        new SrfAutoplayableVideoTeaser(element);
      } else {
        new SrfVideoTeaser(element);
      }
      // mark element, so that it won't be initialized again by this module
      $(element).data('video-teaser-initialized', true);
    });

  if (couldAutoplay) {
    SrfAutoplayInit();
  }
}

function initVideoModal() {
  let $modalElement = $(`[data-id="${VIDEO_MODAL_ID}"]`);

  if ($modalElement.length === 1) {
    new SrfVideoModal(VIDEO_MODAL_ID, $modalElement);
  }
}

/**
 * This function exposes a global function to be used with ab-testing
 * It adds a video to the teaser with the given urn if the teaser is visible and removes it afterwards
 * can be called as such:
 * window.addVideoToTeaser(
 *   'urn:srf:epgEpisode:12357295',
 *   'https://srf.rokka.io/dynamic/noop/c30fc9ff8ed75b092d754bf9023a1be6c9e8915b.mp4',
 *   (urn) => console.log('Great success!', urn),
 *   (urn) => console.log('That did not work:', urn)
 * );
 */
window.addVideoToTeaser = function (
  urn,
  videoUrl,
  successCallback = () => {},
  failureCallback = () => {},
) {
  if (!urn || !videoUrl) {
    return;
  }

  const createVideoElement = (src) => {
    const element = document.createElement('video');
    element.setAttribute('loop', '');
    element.setAttribute('muted', 'true');
    element.setAttribute('autoplay', '');
    element.setAttribute('playsinline', '1');
    element.setAttribute('src', src);
    element.setAttribute(
      'style',
      'position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; object-fit: cover; transition: opacity 0.3s ease-in-out 0s; pointer-events: none; background-color: black; opacity: 0;',
    );
    element.defaultMuted = true;
    element.muted = true;
    element.classList.add('js-animated-teaser');
    return element;
  };

  const targets = document.querySelectorAll(`.js-teaser-ng[data-urn="${urn}"]`);

  targets.forEach((element) => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const videoElement = createVideoElement(videoUrl);

          if (entry.target.matches('.teaser-ng--longform')) {
            entry.target.style.position = 'relative';
            entry.target.prepend(videoElement);
          } else {
            const picture = entry.target.querySelector('picture');
            picture.style.position = 'relative';
            picture.append(videoElement);
          }

          videoElement.addEventListener(
            'playing',
            (e) => {
              e.target.style.opacity = 1;
              successCallback(urn);
            },
            { once: true },
          );
          videoElement.play().catch(() => failureCallback(urn));
        } else {
          const videoElement = entry.target.querySelector(
            '.js-animated-teaser',
          );
          if (videoElement) {
            videoElement.remove();
          }
        }
      });
    });

    observer.observe(element);
  });
};
